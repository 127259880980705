/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';

import firebase from 'firebase';

import config from '@/config';
import log from '@/services/log';

export class User {
  info: firebase.User;

  roles?: string[];

  granularPermissions?: {
    createAndEditOwnCompany?: boolean;
    createAndEditOwnOrganisation?: boolean;
    createAndEditOwnStudies?: boolean;
    viewOwnReports?: boolean;
  };
}

if (config.firebaseConfig && !firebase.apps.length) {
  firebase.initializeApp(config.firebaseConfig);
}

export const useFirebase = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User>(null);

  const getToken = async () => firebase.auth().currentUser?.getIdToken();

  const loginAnonymously = async () => {
    try {
      let userDetails;
      await firebase.auth().signInAnonymously();
      const currentUser = await firebase.auth().currentUser;
      if (currentUser) {
        userDetails = {
          info: currentUser,
        };
        setUser(userDetails);
        return {
          user: userDetails,
          error: null,
        };
      }

      return {
        user: null,
        error: 'No existing user',
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return {
        user: null,
        error,
      };
    }
  };

  const loginWithMagicLink = async (
    link: string,
    email: string
  ): Promise<{
    user: User | null;
    error: any;
  }> => {
    if (firebase.auth().isSignInWithEmailLink(link)) {
      try {
        let userDetails;
        await firebase.auth().signInWithEmailLink(email, link);
        const currentUser = await firebase.auth().currentUser;
        if (currentUser) {
          const tokenResult = await currentUser.getIdTokenResult(true);
          const roles = tokenResult.claims?.roles
            ? [...tokenResult.claims?.roles]
            : [];
          const role = tokenResult.claims?.role;

          if (role && !roles.includes(role)) {
            roles.push(role);
          }

          userDetails = {
            info: currentUser,
            roles,
            granularPermissions: tokenResult.claims?.granularPermissions,
          };
          setUser(userDetails);
          return {
            user: userDetails,
            error: null,
          };
        }

        return {
          user: null,
          error: 'No existing user',
        };
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return {
          user: null,
          error,
        };
      }
    }
  };

  const logOut = async (): Promise<boolean> => {
    try {
      await firebase.auth().signOut();
      setUser(null);
      return true;
    } catch (error) {
      log.error(error);
      return false;
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async u => {
      if (u) {
        const tokenResult = await u.getIdTokenResult(true);
        const roles = tokenResult.claims?.roles
          ? [...tokenResult.claims?.roles]
          : [];
        const role = tokenResult.claims?.role;

        if (role && !roles.includes(role)) {
          roles.push(role);
        }

        setUser({
          info: u,
          roles,
          granularPermissions: tokenResult.claims?.granularPermissions,
        });
      }
      setIsLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return {
    isLoading,
    user,
    getToken,
    loginWithMagicLink,
    logOut,
    loginAnonymously,
  };
};
