export default {
  env: process.env.NODE_ENV,
  disableConsoleLogging: process.env.NEXT_PUBLIC_DISABLE_LOGGING !== 'false',
  supportLink:
    process.env.NEXT_PUBLIC_SUPPORT_LINK || 'support@thoughtandfunction.com',
  googleAnalytics: {
    id: process.env.NEXT_PUBLIC_GA_ID,
  },
  prismic: {
    repo: process.env.NEXT_PUBLIC_PRISMIC_REPO,
    accessToken: process.env.NEXT_PUBLIC_PRISMIC_ACCESS_TOKEN,
  },
  prodDomain: process.env.NEXT_PUBLIC_PROD_DOMAIN,
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
  socialMeta: {
    baseUrl: process.env.NEXT_PUBLIC_SOCIALMETA_BASEURL,
    title: process.env.NEXT_PUBLIC_SOCIALMETA_TITLE,
    description: process.env.NEXT_PUBLIC_SOCIALMETA_DESCRIPTION,
    keywords: process.env.NEXT_PUBLIC_SOCIALMETA_KEYWORDS,
    imageUrl: process.env.NEXT_PUBLIC_SOCIALMETA_IMAGEURL,
    companyName: process.env.NEXT_PUBLIC_SOCIALMETA_COMPANYNAME,
    twitterUser: process.env.NEXT_PUBLIC_SOCIALMETA_TWITTERUSER,
  },
  firebaseConfig: JSON.parse(
    Buffer.from(
      `${process.env.NEXT_PUBLIC_FIREBASE_CONFIG}`,
      'base64'
    ).toString()
  ),
  title: 'Compass',
  name: 'Compass',
  tileColor: '#ffce00',
};
