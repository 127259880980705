import { captureException, captureMessage, Severity } from '@sentry/react';

import config from '@/config';

const info = message => {
  captureMessage(message, { level: Severity.Info });
  // eslint-disable-next-line no-console
  if (!config.disableConsoleLogging) console.info(message);
};
const warn = message => {
  captureMessage(message, { level: Severity.Error });
  // eslint-disable-next-line no-console
  if (!config.disableConsoleLogging) console.warn(message);
};
const error = (message: string | Error) => {
  if (message instanceof Error) captureException(message);
  else captureMessage(message, { level: Severity.Error });
  // eslint-disable-next-line no-console
  if (!config.disableConsoleLogging) console.error(message);
};

const log = {
  info,
  warn,
  error,
};
export default log;
